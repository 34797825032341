import React from "react";
import styled from "styled-components";
import hero from "./Images/hero-page.png";

const Hero = () => {
  return (
    <Container>
      <Wrapper>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"></path>
        </svg>
      </Wrapper>
      <Content>
        <First>
          {" "}
          <h6>We are Web Developers from Nigeria</h6>
          <h1>We build and Deploy websites that Users Love</h1>
          <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda,
            laboriosam. Ad quas odio quibusdam iure reiciendis sapiente, rerum
            ducimus perspiciatis.
          </p>
          <Button>Learn More</Button>
        </First>
        <Second>
          <Img src={hero} />
        </Second>
      </Content>
    </Container>
  );
};

export default Hero;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  color: white;
  height: 600px;
`;
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

  svg {
    position: relative;
    display: block;
    width: calc(300% + 1.3px);
    height: 600px;
    transform: rotateY(180deg);
  }

  path {
    fill: #222163;
  }
`;

const Content = styled.div`
  position: absolute;
  display: flex;
  margin-top: 30px;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const First = styled.div`
  h6 {
    color: #f2a90a;
    font-size: 16px;
    font-weight: 700;
  }

  h1 {
    width: 450px;
    margin: 0;
    font-size: 45px;
  }

  p {
    width: 400px;
    font-size: 16px;
  }
`;

const Second = styled.div``;

const Img = styled.img`
  height: 300px;
`;

const Button = styled.button`
  padding: 15px 40px;
  border: 0;
  border-radius: 0px 30px 0px 30px;
  color: white;
  background: linear-gradient(90deg, #e6257d, #fc8a4c);
  transition: all 0.5s ease-in;
  font-size: 15px;

  :hover {
    cursor: pointer;
  }
`;
