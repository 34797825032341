import React from "react";
import styled from "styled-components";
import pic from "./Images/agen-1.png";

const AgencyComp = () => {
  return (
    <Container>
      <Wrapper>
        <First>
          <AgenImg src={pic} />
        </First>
        <Second>
          <h1>Why Our Agency</h1>
          <Span></Span>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Debitis,
            provident beatae, repellat asperiores sequi error esse assumenda
            quibusdam cumque, alias non earum explicabo sit officiis dignissimos
            architecto accusantium natus dolore nobis blanditiis consequatur!
            Sunt, at!
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi,
            quaerat. Quos reiciendis officiis nulla beatae illo deleniti
            molestias distinctio eius?
          </p>

          <Card>
            <Div1>
              <h1>9875</h1>
              <span> Satisfied Clients</span>
            </Div1>
            <Div1>
              <h1>9875</h1>
              <span> Satisfied Clients</span>
            </Div1>
            <Div1>
              <h1>9875</h1>
              <span> Satisfied Clients</span>
            </Div1>
          </Card>
        </Second>
      </Wrapper>
    </Container>
  );
};

export default AgencyComp;

const Container = styled.div`
  display: flex;
  width: 100%;
  /* height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  width: 80%;
  /* height: 80%; */
  display: flex;
`;

const First = styled.div`
  height: 400px;
  width: 50%;
`;
const Second = styled.div`
  /* background-color: green; */
  height: 300px;
  width: 50%;

  h1 {
    font-size: 38px;
    color: black;
  }

  p {
    color: black;
    font-size: 16px;
  }
`;
const AgenImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    margin-bottom: -20px;
  }
`;

const Div1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  align-items: center;
  justify-content: center;
  height: 80px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; */

  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  h1 {
    margin: 0;
  }
  background-color: #fff;
`;
const Span = styled.div`
  width: 250px;
  height: 5px;
  border-radius: 5px;
  background: linear-gradient(90deg, #d41333, orange);
`;
