import React from "react";
import styled from "styled-components";

const Special = () => {
  return (
    <Container>
      <Wrapper>
        <First>
          <h1>Our Specialization</h1>
          <Dash></Dash>
        </First>
        <Second>
          <Card>
            <Image></Image>
            <h2>Strategy & Research</h2>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse
              consectetur voluptatem, molestias consequuntur excepturi
              architecto facilis reiciendis veniam animi minus perferendis
              praesentium laborum placeat sed?
            </p>
            <Icon></Icon>
          </Card>

          <Card>
            <Image></Image>
            <h2>Strategy & Research</h2>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse
              consectetur voluptatem, molestias consequuntur excepturi
              architecto facilis reiciendis veniam animi minus perferendis
              praesentium laborum placeat sed?
            </p>
            <Icon></Icon>
          </Card>

          <Card>
            <Image></Image>
            <h2>Strategy & Research</h2>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Esse
              consectetur voluptatem, molestias consequuntur excepturi
              architecto facilis reiciendis veniam animi minus perferendis
              praesentium laborum placeat sed?
            </p>
            <Icon></Icon>
          </Card>
        </Second>
      </Wrapper>
    </Container>
  );
};

export default Special;

const Container = styled.div`
  width: 100%;
  height: 80vh;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 80%;
  height: 80%;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const First = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
  h1 {
    color: #1f2470;
    font-size: 40px;
    /* margin: 0; */
  }
`;
const Dash = styled.div`
  width: 250px;
  height: 5px;
  border-radius: 5px;
  background: linear-gradient(90deg, #d41333, orange);
  margin: 0;
`;
const Second = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Image = styled.div``;
const Card = styled.div`
  background-color: whitesmoke;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 30%;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

  h2 {
    color: #1f2470;
    font-size: 20px;
    font-weight: 800;
  }

  p {
    font-size: 16px;
    text-align: center;
    color: #686d91;
  }
`;
const Icon = styled.div``;
