import React from "react";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero";
import Homescreen from "./Components/Homescreen";

const App = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Homescreen />
    </div>
  );
};

export default App;
