import React from "react";
import AgencyComp from "./AgencyComp";
import Special from "./Special";

const Homescreen = () => {
  return (
    <div>
      <AgencyComp />
      <Special />
    </div>
  );
};

export default Homescreen;
