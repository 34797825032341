import React from "react";
import styled from "styled-components";

const Header = () => {
  return (
    <Container>
      <Wrapper>
        <Logo>Resolution</Logo>
        <Navigation>
          <Nav>home</Nav>
          <Nav>home</Nav>
          <Nav>home</Nav>
          <Nav>home</Nav>
          <Nav>home</Nav>
          <Nav>home</Nav>
          <Button>
            {" "}
            <span> </span> get a quote
          </Button>
        </Navigation>
      </Wrapper>
    </Container>
  );
};

export default Header;
const Container = styled.div`
  width: 100%;
  height: 90px;
  background-color: #222163;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 80%;
  height: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.h1`
  font-size: 25px;
  color: white;
  font-weight: 800;
`;
const Navigation = styled.div`
  /* width: 60%; */
  display: flex;
  /* background: white; */
  justify-content: space-between;
  align-items: center;
`;
const Nav = styled.div`
  color: white;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 15px;
`;
const Button = styled.button`
  padding: 15px 40px;
  border: 0;
  border-radius: 0px 30px 0px 30px;
  margin-left: 30px;
  background: linear-gradient(90deg, #e6257d, #fc8a4c);
  transition: all 0.5s ease-in;
  font-size: 15px;
  color: white;

  :hover {
    cursor: pointer;
    transform: scale(0.9);
  }
`;
